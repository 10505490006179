import { AxiosError } from 'axios';
import React, { FC, useState } from 'react';
import { AlertTriangle, CheckCircle, XOctagon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Recaptcha from 'react-recaptcha';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Textarea } from '../components/Textarea';
import { H4 } from '../components/Typography';
import { Config } from '../config';
import { sendContactInfo as sendContactInfoAction } from '../lib/actions/contact';
import { getBodyMessage } from '../utils/function-utils';
import { Colors } from '../utils/style-utils';
import { Container } from './commonStyles';

const HelpText = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333
  margin: 0 0 14px;
`;

interface FormValues {
  subject: string;
  name: string;
  email: string;
  message: string;
}

export const ContactForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      subject: '',
      name: '',
      email: '',
      message: '',
    },
  });
  const dispatch = useDispatch();
  const [recaptchaToken, setToken] = useState('');

  async function onSubmit(values: FormValues) {
    if (!recaptchaToken) {
      toast(
        <span className="flex items-center">
          <AlertTriangle color={Colors.Blue900} className="mr-2" />
          Recaptcha is not valid
        </span>
      );
      return;
    }

    try {
      await dispatch(sendContactInfoAction(values, recaptchaToken));
      toast.success('Your enquiry was sent correctly.', {
        icon: <CheckCircle className="w-8" size={32} color="#3CB043" />,
      });
    } catch (err) {
      toast.error(getBodyMessage(err as AxiosError), {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
    } finally {
      reset();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Contact Us</H4>

        <Input
          label="Name"
          className="mb-4"
          error={!!errors.name}
          helpText={errors.name && <HelpText>Name is required</HelpText>}
          inputStyle={{ color: '#333' }}
          {...register('name', { required: true })}
        />

        <Input
          label="Email"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>{errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}</HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <Input
          label="Subject"
          className="mb-4"
          error={!!errors.subject}
          helpText={errors.subject && <HelpText>Subject is required</HelpText>}
          inputStyle={{ color: '#333' }}
          {...register('subject', { required: true })}
        />

        <Textarea
          label="Message"
          className="mb-4"
          error={!!errors.message}
          helpText={errors.message && <HelpText>Message is required</HelpText>}
          inputStyle={{ color: '#333' }}
          {...register('message', { required: true })}
        />

        <Recaptcha
          sitekey={Config.RecaptchaKey}
          render="explicit"
          expiredCallback={() => setToken('')}
          verifyCallback={(response = '') => setToken(response)}
        />

        <div className="flex mt-4">
          <Button type="submit" disabled={isSubmitting} fullWidth>
            Submit
          </Button>
        </div>
      </Container>
    </form>
  );
};
