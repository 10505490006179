import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { Api } from '../../api/api';
import ActionTypes from '../actionTypes';
import { SdkAction, createSdkAction, onFulfill, onPending, onReject } from '../utils/actions';

export function sendContactInfo(
  form: {
    subject: string;
    name: string;
    email: string;
    message: string;
  },
  recaptchaToken: string
): SdkAction {
  return createSdkAction(ActionTypes.SEND_CONTACT_INFO, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.SEND_CONTACT_INFO) });

      return sdk
        .request('/contact', {
          body: { ...form, recaptcha_response_token: recaptchaToken },
          method: 'post',
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.SEND_CONTACT_INFO),
            payload: res.data,
          });
          return Promise.resolve(res.data);
        })
        .catch((res: AxiosError) => {
          dispatch({ type: onReject(ActionTypes.SEND_CONTACT_INFO) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}
